<template>
  <div
    class="tw-bg-primary-900 tw-fixed tw-bottom-0 md:tw-bottom-5 md:tw-left-1/2 md:tw-min-w-[680px] tw-w-full md:tw-w-auto md:tw--translate-x-1/2 tw-z-20"
  >
    <div class="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-p-4 md:tw-p-6">
      <div class="tw-flex tw-items-center tw-gap-5 tw-flex-grow">
        <img
          loading="lazy"
          alt="cookie image"
          src="assets/images/cookie.png"
          class="tw-w-900 tw-h-900 max-sm:tw-hidden"
        />

        <i18n-t keypath="terms.cookie" tag="p" class="heading-h5.1 tw-flex-shrink" scope="global">
          <template #link>
            <nuxt-link-locale to="/privacy" class="tw-underline tw-font-semibold tw-lowercase">
              {{ t('terms.privacy.title', 2) }}
            </nuxt-link-locale>
          </template>
        </i18n-t>
      </div>

      <de-button
        type="submit"
        :category="ButtonCategoryOptions.secondary"
        :size="ButtonSizeOptions.medium"
        :label="`${$t('common.buttons.gotIt')}!`"
        class="tw-flex-shrink-0"
        @click="setCookieConsent"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import {
  ButtonCategoryOptions,
  ButtonSizeOptions,
} from '~/shared/components/lib/button/button.entity';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import { useAppStore } from '~/store/app';

const { t } = useI18n();
const { setCookieConsent } = useAppStore();
</script>
